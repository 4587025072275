import qs from "qs";
import { useHistory } from "react-router-dom";
import { RecruitmentStatus } from "../types";

const ITEMS_PER_PAGE = 10;

export interface PaginationProps {
  orderBy: "position" | "name" | "surname" | "confirmedAt" | "status";
  onSortColumn: (newOrderBy: string) => void;
  order: "desc" | "asc";
  rows: number;
  totalRows?: number;
  onChangeLimit: (newLimit: {}) => void;
  onChangeFilter: (filter?: {}) => void;
  onCursorUpdate: (newCursor: string | undefined) => void;
  filter: {
    status?: RecruitmentStatus[]
    nameSurname?: string;
    confirmedAt?: string[];
  };
}

function useDecodeUrl() {
  const history = useHistory();
  const { rows, orderBy, order, ...filter } = qs.parse(
    history.location.search,
    { ignoreQueryPrefix: true }
  );

  const nameSurname = filter.nameSurname;

  if (typeof nameSurname === "string" && nameSurname.length > 0 && nameSurname.length < 3) {

    filter.nameSurname = undefined;
    const query = qs.stringify({
      rows,
      orderBy,
      order,
      ...filter
    });
    history.replace(
      {
        ...history.location,
        search: query
      }
    )
  }

  return {
    rows,
    orderBy,
    order,
    filter,
    history,
  };
}

function usePagination({
  orderBy: initialOrder,
  order: initialSortingOrder,
}: Pick<PaginationProps, "order" | "orderBy">) {

  const { rows, orderBy, order, filter, history } = useDecodeUrl();


  const handleSortColumn = (newOrderBy: string) => {
    const newSortingOrder = order === "asc" ? "desc" : "asc";

    const query = qs.stringify({
      orderBy: newOrderBy,
      order: newSortingOrder,
      rows,
      ...filter,
    });
    history.replace({
      ...history.location,
      search: query,
    });
  };

  const handleChangeLimit = (props: {
    target: {
      value: {};
    };
  }) => {
    const pagination = {
      rows: props.target.value,
    };
    const query = qs.stringify(
      { ...pagination, orderBy, order, ...filter },
      { arrayFormat: "brackets" }
    );
    history.replace({
      ...history.location,
      search: query,
    });
  };

  const handleSetFilter = (filter?: {}) => {
    const pagination = { rows, order };

    const query = qs.stringify({ ...pagination, orderBy, ...filter });
    history.replace({ ...history.location, search: query });
  };

  return {
    onSortColumn: handleSortColumn,
    orderBy: orderBy || initialOrder,
    order: order || initialSortingOrder,
    rows: parseInt(String(rows || ITEMS_PER_PAGE), 10),
    filter,
    onChangeLimit: handleChangeLimit,
    onChangeFilter: handleSetFilter,
  } as PaginationProps;
}

export default usePagination;

import {
  TableFooter,
  TableRow,
  Select,
  MenuItem,
  TableCell,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { PaginationProps } from "../utils/pagination";

interface TableFooterPaginationProps
  extends Pick<PaginationProps, "rows" | "onChangeLimit"> {}

export const TableFooterPagination: React.FC<TableFooterPaginationProps> = ({
  rows,
  onChangeLimit,
}) => {
  const classes = useStyles();
  return (
    <TableFooter>
      <TableRow>
        <TableCell className={classes.styledTablePagination} padding="none">
          <div className={classes.stylesRowsWrapper}>
            rows
            <Select
              onChange={onChangeLimit}
              name="rows"
              className={classes.styledSelectRow}
              id="rows"
              value={rows}
              color="secondary">
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </div>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

const useStyles = makeStyles(() => ({
  styledTablePagination: {
    minWidth: 250,
    position: "fixed",
    margin: 0,
    height: 30,
    right: 0,
    bottom: 0,
    background: "#fff",
    padding: 0,
  },
  stylesRowsWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    height: "100%",
  },
  styledSelectRow: {
    height: "100%",
    "& .MuiSelect-icon": {
      transform: "rotate(180deg)",
    },
  },
}));

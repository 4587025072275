import { RecruitmentStatus } from "../types.d";

export const recruitmentStatuses = [
  { value: RecruitmentStatus.NEW, label: "New" },
  {
    value: RecruitmentStatus.ACCEPTED_FOR_THE_FIRST_STAGE,
    label: "Accepted for the first stage",
  },
  {
    value: RecruitmentStatus.INVITED_FOR_THE_FIRST_STAGE,
    label: "Invited for the first stage",
  },
  {
    value: RecruitmentStatus.AFTER_THE_FIRST_STAGE,
    label: "After the first stage",
  },
  {
    value: RecruitmentStatus.CANDIDATE_DELETED_CV,
    label: "Candidate deleted CV",
  },
  {
    value: RecruitmentStatus.REJECTED_WITHOUT_FUTURE_POTENTIAL,
    label: "Rejected without future potential",
  },
  {
    value: RecruitmentStatus.REJECTED_WITH_FUTURE_POTENTIAL,
    label: "Rejected with future potential",
  },
  {
    value: RecruitmentStatus.INFORMED_ABOUT_REJECTION_WITHOUT_FUTURE_POTENTIAL,
    label: "Informed about rejection without future potential",
  },
  {
    value: RecruitmentStatus.INFORMED_ABOUT_REJECTION_WITH_FUTURE_POTENTIAL,
    label: "Informed about rejection with future potential",
  },
  {
    value: RecruitmentStatus.ACCEPTED_FOR_THE_SECOND_STAGE,
    label: "Accepted for the second stage",
  },
  {
    value: RecruitmentStatus.INVITED_FOR_THE_SECOND_STAGE,
    label: "Invited for the second stage",
  },
  {
    value: RecruitmentStatus.AFTER_THE_SECOND_STAGE,
    label: "After the second stage",
  },
  {
    value: RecruitmentStatus.ACCEPTED_FOR_THE_THIRD_STAGE,
    label: "Accepted for the third stage",
  },
  {
    value: RecruitmentStatus.INVITED_FOR_THE_THIRD_STAGE,
    label: "Invited for the third stage",
  },
  {
    value: RecruitmentStatus.AFTER_THE_THIRD_STAGE,
    label: "After the third stage",
  },
  { value: RecruitmentStatus.ACCEPTED_FOR_WORK, label: "Accepted for work" },
  {
    value: RecruitmentStatus.RESIGNED_FOR_ANOTHER_JOB_OFFER,
    label: "Resigned for another job offer",
  },
  {
    value: RecruitmentStatus.CLOSED_AS_DUPLICATE,
    label: "Closed as duplicate",
  },
];

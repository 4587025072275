import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  styledWrapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > div, & > button, & > a": {
      marginBottom: 20,
    },
    "&:not(:first-child)": {
      marginTop: 50,
    },
  },
});

export const InlineWrapper: React.FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.styledWrapper}>{children}</div>;
};

export default InlineWrapper;

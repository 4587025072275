import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useRouteMatch } from "react-router-dom";
import { Routes } from "../../routes";
import { Candidate } from "../../types";
import { CANDIDATE_DETAILS_FRAGMENT } from "./candidateFragment";

const GET_CANDIDATE = gql`
 ${CANDIDATE_DETAILS_FRAGMENT}
  query candidate($id: ID!) {
    candidate(id: $id) {
      ...candidateDetails
    }
  }
`;

interface IParams {
  params: { id: string };
}

interface ICandidateData {
  candidate: Candidate;
}

export interface IWithCandidate extends ICandidateData { }

function useCandidateQuery() {
  const { params } = useRouteMatch(`/${Routes.Candidate}/:id`) as IParams;

  const { loading, error, data } = useQuery(GET_CANDIDATE, {
    variables: { id: params.id },
  });

  const candidate = data?.candidate ?? {};
  return { candidate, loading, error };
}

export default useCandidateQuery;

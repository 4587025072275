import {
  Checkbox,
  ListItemText,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { recruitmentStatuses } from "../../constants";

function getNameForStatus(selected: unknown) {
  if (!Array.isArray(selected) || typeof selected[0] !== "string") {
    return "";
  }
  return selected
    .map(
      (elem) =>
        recruitmentStatuses.find((status) => status.value === elem)?.label
    )
    .join(", ");
}

export function RecruitmentStatusPicker({
  onChange,
  value,
  name,
}: TextFieldProps & { value: string[] }) {
  return (
    <TextField
      id="nameSurname-select"
      label="Status"
      select
      value={value}
      variant="outlined"
      onChange={onChange}
      style={{ width: 300 }}
      SelectProps={{
        multiple: true,
        renderValue: (selected) => getNameForStatus(selected),
      }}
      InputProps={{ name }}>
      <MenuItem value={[]}></MenuItem>
      {recruitmentStatuses.map((status) => (
        <MenuItem key={status.value} value={status.value}>
          <Checkbox checked={value.indexOf(status.value) > -1} />
          <ListItemText primary={status.label} />
        </MenuItem>
      ))}
    </TextField>
  );
}

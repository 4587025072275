import { IconButton } from "@mui/material";
import {ExitToApp} from "@mui/icons-material";
import { client } from "../gqlClient";
import { signOut } from "../utils/authorization";

export default function LogoutButton() {
  function handleLogOut() {
    setTimeout(() => {
      try {
        signOut();
        client.clearStore();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }, 200);
  }

  return (
      <IconButton onClick={handleLogOut}>
        <ExitToApp style={{ color: "#fff" }} />
      </IconButton>
  );
}

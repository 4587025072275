export enum CandidateStatus {
  NOT_SEEKING_NEW_OPPORTUNITIES = "NOT_SEEKING_NEW_OPPORTUNITIES",
  MIGHT_CONSIDER_NEW_OFFERS = "MIGHT_CONSIDER_NEW_OFFERS",
  THINKING_OF_FINDING_NEW_OPPORTUNITIES = "THINKING_OF_FINDING_NEW_OPPORTUNITIES",
  ACTIVELY_LOOKING_FOR_NEW_OPPORTUNITIES = "ACTIVELY_LOOKING_FOR_NEW_OPPORTUNITIES"
}

export enum RecruitmentStatus {
  NEW = "NEW",
  AFTER_THE_FIRST_STAGE = "AFTER_THE_FIRST_STAGE",
  AFTER_THE_SECOND_STAGE = "AFTER_THE_SECOND_STAGE",
  AFTER_THE_THIRD_STAGE = "AFTER_THE_THIRD_STAGE",
  REJECTED_WITHOUT_FUTURE_POTENTIAL = "REJECTED_WITHOUT_FUTURE_POTENTIAL",
  REJECTED_WITH_FUTURE_POTENTIAL = "REJECTED_WITH_FUTURE_POTENTIAL",
  INFORMED_ABOUT_REJECTION_WITH_FUTURE_POTENTIAL = "INFORMED_ABOUT_REJECTION_WITH_FUTURE_POTENTIAL",
  INFORMED_ABOUT_REJECTION_WITHOUT_FUTURE_POTENTIAL = "INFORMED_ABOUT_REJECTION_WITHOUT_FUTURE_POTENTIAL",
  ACCEPTED_FOR_THE_FIRST_STAGE = "ACCEPTED_FOR_THE_FIRST_STAGE",
  INVITED_FOR_THE_FIRST_STAGE = "INVITED_FOR_THE_FIRST_STAGE",
  ACCEPTED_FOR_THE_SECOND_STAGE = "ACCEPTED_FOR_THE_SECOND_STAGE",
  INVITED_FOR_THE_SECOND_STAGE = "INVITED_FOR_THE_SECOND_STAGE",
  ACCEPTED_FOR_THE_THIRD_STAGE = "ACCEPTED_FOR_THE_THIRD_STAGE",
  INVITED_FOR_THE_THIRD_STAGE = "INVITED_FOR_THE_THIRD_STAGE",
  ACCEPTED_FOR_WORK = "ACCEPTED_FOR_WORK",
  CLOSED_AS_DUPLICATE = "CLOSED_AS_DUPLICATE",
  RESIGNED_FOR_ANOTHER_JOB_OFFER = "RESIGNED_FOR_ANOTHER_JOB_OFFER",
  CANDIDATE_DELETED_CV = "CANDIDATE_DELETED_CV"
}

export interface Consent {
  id: string
  tag: ConsentFormName
  wording: string
  header?: string
  order: number;
  purpose: ConsentPurpose
  isRequired: boolean
}

export enum ConsentPurpose {
  REMOVE_SENSITIVE_DATA,
  AGREE_FOR_FUTURE_PROCESSES
}

export enum ConsentFormName {
  CANDIDATE,
  CONSULTATION_REQUEST,
  CONTACT_REQUEST,
  SUBSCRIBING,
  CONSENT_UPDATE_REQUEST
}

export interface RecruitmentProcessHistory {
  recruitmentStatus: RecruitmentStatus
  recruiterNote?: string
  createdAt?: string
  author?: string
}

export interface RelatedApplication {
  id: string
  confirmedAt?: string;
  position?: string
  currentStatus: RecruitmentStatus
}

export interface CreateCandidatePayload {
  position?: string;
  foundOutFrom?: string;
  name: string;
  surname: string;
  email: string;
  phoneNumber?: string;
  availabilityDate?: string;
  school?: string;
  githubUrl?: string;
  educationStatus?: string;
  note?: string;
  withApplication: boolean;
}

export interface Candidate {
  id: string
  email?: string
  name?: string
  surname?: string
  phoneNumber?: string
  educationStatus?: string
  currentStatus: RecruitmentStatus
  recruitmentProcessHistory: [RecruitmentProcessHistory]
  relatedApplications: [RelatedApplication]
  school?: string
  note?: string
  githubUrl?: string
  acceptedConsents: [Consent]
  foundOutFrom?: string
  position?: string
  createdAt?: string
  recruiterNote?: string
  isConfirmed: boolean
  withNewApplication: boolean
  createdFromIp?: string
  confirmedFromIp?: string
  confirmedAt?: string
  applicationFileId?: string
  availabilityDate?: string
  updatedAt?: string
  phoneNumberHash?: string
  emailHash?: string
  githubUrlHash?: string
  signedLink?: string
}
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";
import { useHandleClick } from "./useHandleClick";
import { recruitmentStatuses } from "../../../constants/recruitmentStatuses";
import { RelatedApplication } from "../../../types";

const StyledMenuItem = styled(MenuItem)({
  borderBottom: "1px solid #e6e6e6",
  "&:last-of-type": {
    borderBottom: "none",
  },
});

function ApplicationDropdown({
  relatedApplications,
  id,
}: {
  relatedApplications: RelatedApplication[];
  id: string;
}) {
  const [handleClick] = useHandleClick();

  return (
    <Select
      variant="outlined"
      color="primary"
      value={id}
      onChange={handleClick}>
      {relatedApplications
        ? relatedApplications.map((application) => (
            <StyledMenuItem key={application.id} value={application.id}>
              <Grid container direction="column" alignItems="flex-start">
                <Grid item container justifyContent="space-between" gap={1}>
                  <Grid item>{application.position}</Grid>
                  {application.confirmedAt && (
                    <Grid item>
                      {new Date(application.confirmedAt).toLocaleDateString()}
                    </Grid>
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="caption" color="primary">
                    {
                      recruitmentStatuses.find(
                        (status) => status.value === application.currentStatus
                      )?.label
                    }
                  </Typography>
                </Grid>
              </Grid>
            </StyledMenuItem>
          ))
        : null}
    </Select>
  );
}

export default ApplicationDropdown;

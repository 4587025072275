import { makeStyles, createStyles} from '@mui/styles';
import {LinearProgress, Theme} from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    margin: {
      margin: theme.spacing(1),
    },
  }),
);

export default function CustomizedProgressBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress color="primary" className={classes.margin} />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { RecruitmentStatusPicker } from "./RecruitmentStatusPicker";
import { DateTimePicker } from "./DatePicker";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { PaginationProps } from "../../utils/pagination";

const useStyles = makeStyles({
  styledInput: {
    "@media (max-width:500px)": {
      width: "100% !important",
    },
  },
});

interface FilterFields {
  nameSurname: string | undefined;
  status: string[];
  dateFrom: string | undefined;
  dateTo: string | undefined;
}

const initialState: FilterFields = {
  nameSurname: "",
  status: [],
  dateFrom: "",
  dateTo: "",
};

export default function CandidatesFilter({
  onChangeFilter,
  filter,
}: Pick<PaginationProps, "onChangeFilter" | "filter">) {
  const [filtersState, setFiltersState] = useState(initialState);

  const [isSearchable, setIsSearchable] = useState(true);

  useEffect(() => {
    const newState = {
      nameSurname: filter.nameSurname,
      status: Array.isArray(filter.status)
        ? filter.status
        : filter.status
        ? [filter.status]
        : [],
      dateFrom:
        filter.confirmedAt && filter.confirmedAt.length === 2
          ? moment(filter.confirmedAt[0]).format("yyyy-MM-DD")
          : "",
      dateTo:
        filter.confirmedAt && filter.confirmedAt.length === 2
          ? moment(filter.confirmedAt[1]).format("yyyy-MM-DD")
          : "",
    };
    setFiltersState(newState);
  }, [filter]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFiltersState({
      ...filtersState,
      [event.target.name]: event.target.value,
    });
  }

  function onKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter" && isSearchable) {
      submitFilters();
    }
  }

  function submitFilters() {
    const newFilter: {
      nameSurname?: string;
      status?: string[];
      confirmedAt?: Date[];
    } = {
      nameSurname: filtersState.nameSurname,
      status: filtersState.status,
    };
    if (filtersState.dateFrom || filtersState.dateTo) {
      const dateFrom = new Date(filtersState.dateFrom || "01-01-2000");
      const dateTo = filtersState.dateTo
        ? new Date(filtersState.dateTo)
        : new Date();
      if (dateFrom.getTime() <= dateTo.getTime()) {
        newFilter.confirmedAt = [dateFrom, dateTo];
      }
    }
    onChangeFilter(newFilter);
  }

  function resetFilters() {
    onChangeFilter({});
    setFiltersState(initialState);
  }

  const handleNameSurnameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value.length === 0 || event.target.value.length > 2) {
      setIsSearchable(true);
    } else {
      setIsSearchable(false);
    }

    setFiltersState({
      ...filtersState,
      [event.target.name]: event.target.value,
    });
  };

  const classes = useStyles();

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item className={classes.styledInput}>
        <TextField
          id="nameSurname"
          variant="outlined"
          inputProps={{
            key: "nameSurname",
            name: "nameSurname",
          }}
          onChange={handleNameSurnameChange}
          label="Name"
          error={!isSearchable}
          helperText={!isSearchable && "Minimum length : 3"}
          autoComplete="off"
          value={filtersState.nameSurname ?? ""}
          onKeyPress={onKeyPress}
          className={classes.styledInput}
          color="secondary"
        />
      </Grid>
      <Grid item className={classes.styledInput}>
        <RecruitmentStatusPicker
          onChange={handleChange}
          value={filtersState.status}
          name="status"
          onKeyPress={onKeyPress}
          className={classes.styledInput}
          color="secondary"
        />
      </Grid>
      <Grid item className={classes.styledInput}>
        <DateTimePicker
          label="Date from"
          name="dateFrom"
          id="date-from-picker"
          value={filtersState.dateFrom}
          onChange={handleChange}
          className={classes.styledInput}
          color="secondary"
        />
      </Grid>
      <Grid item className={classes.styledInput}>
        <DateTimePicker
          label="Date to"
          name="dateTo"
          id="date-to-picker"
          value={filtersState.dateTo}
          onChange={handleChange}
          className={classes.styledInput}
          color="secondary"
        />
      </Grid>
      <Grid item>
        <Grid container wrap="nowrap" spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={resetFilters}
              variant="outlined"
              color="secondary"
              size="large"
              style={{ height: 56, width: 105 }}>
              RESET
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={submitFilters}
              variant="contained"
              size="large"
              disabled={!isSearchable}
              color="secondary"
              style={{
                height: 56,
                width: 105,
              }}>
              SEARCH
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

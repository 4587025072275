import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Alert,
} from '@mui/material';
import { useCreateApplication } from './useCreateApplication';
import CustomizedProgressBar from '../../components/Loader';
import { StyledContainer } from '../../components/StyledContainer';

import { makeStyles } from '@mui/styles';
import { CreateCandidatePayload } from '../../types';

const useStyles = makeStyles({
  styledInput: {
    '& >div, button': {
      minWidth: 250,
    },

    '@media (max-width:500px)': {
      width: '100% !important',
    },
  },
  formControl: {
    minWidth: 250,
  },
  errorMessage: {
    color: '#d32f2f',
    fontSize: "0.75rem"
  }
});

export const ApplicationForm = () => {
  const { handleCreateApplication, formError, isSuccess, isLoading } =
    useCreateApplication();

  const handleSendData = ({
    formData,
    uploadedFile,
  }: {
    formData: CreateCandidatePayload;
    uploadedFile: File | undefined;
  }) => {
    handleCreateApplication({
      formData: formData,
      uploadedFile: uploadedFile,
    });
  };

  const positionsOptions = [
    { value: 'DevOps', label: 'DevOps' },
    { value: 'Software Developer', label: 'Software Developer' },
    { value: 'Project Manager', label: 'Project Manager' },
    { value: 'Tester', label: 'Tester' },
    { value: 'Intern', label: 'Intern' },
    { value: 'Other', label: 'Other' },
  ];

  const educationLevels = [
    { value: 'part_time_student', label: 'Part-Time Student' },
    { value: 'graduated', label: 'Degree Completed' },
    { value: 'not_studying', label: 'No Degree' },
    { value: 'student', label: 'Full-Time Student' },
  ];

  const foundOutFromOptions = [
    { value: 'Job fairs', label: 'Job fairs' },
    { value: 'Friends', label: 'Friends' },
    { value: 'Internet', label: 'Internet' },
    { value: 'BTP Blog', label: 'BTP Blog' },
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Other', label: 'Other' },
  ];

  const formik = useFormik({
    initialValues: {
      name: '',
      surname: '',
      email: '',
      phoneNumber: '',
      availabilityDate: '',
      school: '',
      foundOutFrom: '',
      githubUrl: '',
      educationStatus: '',
      note: '',
      attachmentCV: undefined,
      withApplication: true,
      position: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name required'),
      surname: Yup.string().required('Surname required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('E-mail Required'),
      note: Yup.string().max(500, 'Must be 500 characters or less'),
      attachmentCV: Yup.mixed<File>()
        .required('Attachment CV required')
        .test('fileSize', 'File Size is too large', (value) => {
          if (value) {
            return value.size <= 5000000;
          }
          return false;
        }),
    }),
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        ...values,
      };
      delete payload.attachmentCV;
      handleSendData({ formData: payload, uploadedFile: values.attachmentCV });
      resetForm();
    },
  });

  const { values, errors, touched, handleSubmit, handleChange } = formik;

  const classes = useStyles();

  return (
    <StyledContainer>
      <Typography variant="h4" gutterBottom style={{ marginBottom: 60 }}>
        New Candidate
      </Typography>
      <form className="contact-form" onSubmit={handleSubmit}>
        <Grid container justifyContent="flex-start" spacing={3}>
          <Grid item className={classes.styledInput}>
            <TextField
              id="name"
              variant="outlined"
              name="name"
              onChange={handleChange}
              label="Name*"
              autoComplete="off"
              value={values.name}
              className={classes.styledInput}
              color="secondary"
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
          </Grid>
          <Grid item className={classes.styledInput}>
            <TextField
              id="surname"
              variant="outlined"
              name="surname"
              onChange={handleChange}
              label="Surname*"
              autoComplete="off"
              value={values.surname}
              className={classes.styledInput}
              color="secondary"
              error={touched.surname && Boolean(errors.surname)}
              helperText={touched.surname && errors.surname}
            />
          </Grid>
          <Grid item className={classes.styledInput}>
            <TextField
              id="email"
              name="email"
              variant="outlined"
              onChange={handleChange}
              label="Email*"
              autoComplete="off"
              value={values.email}
              className={classes.styledInput}
              color="secondary"
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>
          <Grid item className={classes.styledInput}>
            <TextField
              id="phoneNumber"
              name="phoneNumber"
              variant="outlined"
              onChange={handleChange}
              label="Phone number"
              autoComplete="off"
              value={values.phoneNumber}
              className={classes.styledInput}
              color="secondary"
            />
          </Grid>
          <Grid item className={classes.styledInput}>
            <FormControl color="secondary">
              <InputLabel id="demo-simple-select-label">Position</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                className={classes.formControl}
                id="position"
                name="position"
                value={values.position}
                onChange={handleChange}
                color="secondary"
              >
                {positionsOptions.map((position) => (
                  <MenuItem key={position.value} value={position.value}>
                    {position.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item className={classes.styledInput}>
            <TextField
              id="availabilityDate"
              name="availabilityDate"
              variant="outlined"
              onChange={handleChange}
              label="Availability date"
              autoComplete="off"
              value={values.availabilityDate}
              className={classes.styledInput}
              color="secondary"
            />
          </Grid>
          <Grid item className={classes.styledInput}>
            <TextField
              id="school"
              name="school"
              variant="outlined"
              onChange={handleChange}
              label="University name"
              autoComplete="off"
              value={values.school}
              className={classes.styledInput}
              color="secondary"
            />
          </Grid>

          <Grid item className={classes.styledInput}>
            <FormControl color="secondary">
              <InputLabel id="demo-simple-select-label">
                Found out from
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                className={classes.formControl}
                id="foundOutFrom"
                name="foundOutFrom"
                value={values.foundOutFrom}
                onChange={handleChange}
                color="secondary"
              >
                {foundOutFromOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item className={classes.styledInput}>
            <TextField
              id="githubUrl"
              variant="outlined"
              name="githubUrl"
              onChange={handleChange}
              label="Github url"
              autoComplete="off"
              value={values.githubUrl}
              className={classes.styledInput}
              color="secondary"
            />
          </Grid>
          <Grid item className={classes.styledInput}>
            <FormControl color="secondary">
              <InputLabel id="demo-simple-select-label">Education</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                className={classes.formControl}
                id="educationStatus"
                name="educationStatus"
                value={values.educationStatus}
                onChange={handleChange}
                color="secondary"
              >
                {educationLevels.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item className={classes.styledInput} style={{ width: '100%' }}>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              component="label"
              style={{
                height: 56,
              }}
            >
              Upload CV
              <input
                name="attachmentCV"
                id="attachmentCV"
                type="file"
                onChange={(e) => {
                  if (e.target?.files?.[0]) {
                    formik.setFieldValue('attachmentCV', e.target.files[0]);
                  }
                }}
                hidden
                accept=".pdf, .jpg, .png, .doc, .docx, application/pdf, image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              />
            </Button>
            <div>
              {touched.attachmentCV && errors.attachmentCV && (
                <div className={classes.errorMessage} style={{ marginTop: 20 }}>
                  {errors.attachmentCV}
                </div>
              )}
            </div>

            <div className="attachments-cotainer">
              {formik.values.attachmentCV && (
                <h5
                // @ts-ignore
                  key={formik.values.attachmentCV.name}
                  className="attachment-name"
                >
                  {/* @ts-ignore */}
                  {formik.values.attachmentCV.name}
                </h5>
              )}
            </div>
            <div style={{ marginTop: 10, fontSize: 14, color: '#808080' }}>
              Allowed formats: .pdf .doc .docx .png .jpg
            </div>
          </Grid>
          <Grid item className={classes.styledInput} style={{ width: '100%' }}>
            <TextField
              id="note"
              name="note"
              label="Note"
              multiline
              rows={5}
              onChange={handleChange}
              value={values.note}
              style={{ width: '100%' }}
              color="secondary"
              error={touched.note && Boolean(errors.note)}
              helperText={touched.note && errors.note}
            />
          </Grid>
          <Grid item style={{ width: '100%', display: 'flex' }}>
            <Button
              // onClick={submitFilters}
              variant="contained"
              size="large"
              color="secondary"
              type="submit"
              style={{
                height: 56,
                margin: '0 auto',
              }}
            >
              Create Candidate
            </Button>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            {isLoading && <CustomizedProgressBar />}
          </Grid>
          <Grid item style={{ margin: '0 auto' }}>
            {formError ? (
              <Alert severity="error">{formError}</Alert>
            ) : isSuccess && !formError ? (
              <Alert severity="success">Candidate created successfully</Alert>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </form>
    </StyledContainer>
  );
};

import React from "react";
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TableLabels, HeadCellProps } from "./TableLabels";
import { PaginationProps } from "../utils/pagination";
import { TableFooterPagination } from "./TablePagination";
import { Candidate } from "../types";

interface TableViewProps extends PaginationProps {
  candidates: Candidate[];
  headCells: HeadCellProps[];
  tableBody?: JSX.Element[];
  fetchMoreHandler: () => Promise<void>;
  newCursor: string | undefined;
  isLoadingMore: boolean;
}

export const TableView: React.FC<TableViewProps> = ({
  headCells,
  orderBy,
  onSortColumn,
  fetchMoreHandler,
  isLoadingMore,
  order,
  tableBody,
  filter,
  candidates,
  onChangeFilter,
  newCursor,
  ...paginationProps
}) => {
  const classes = useStyles();
  return (
    <TableContainer
      onScroll={(event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        if (scrollTop + clientHeight + 50 > scrollHeight && !isLoadingMore) {
          fetchMoreHandler().catch((error) => {
            console.log(error);
          });
        }
      }}
      className={classes.styledTableContainer}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableLabels
              headCells={headCells}
              orderBy={orderBy}
              onSortColumn={onSortColumn}
              order={order}
            />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBody}
          {paginationProps.children}
          <TableRow>
            <TableCell colSpan={7} align="center">
              {isLoadingMore ? (
                <CircularProgress size={20} />
              ) : (
                <Button onClick={fetchMoreHandler} disabled={!newCursor}>
                  Load more candidates
                </Button>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
        <TableFooterPagination
          onChangeLimit={paginationProps.onChangeLimit}
          rows={paginationProps.rows}
        />
      </Table>
    </TableContainer>
  );
};

const useStyles = makeStyles(() => ({
  styledTableContainer: {
    maxHeight: "calc(100vh - 250px)",
  },
}));

export default TableView;

import gql from "graphql-tag";

export const CANDIDATE_DETAILS_FRAGMENT = gql`
  fragment candidateDetails on Candidate {
    id
    email
    name
    surname
    phoneNumber
    educationStatus
    currentStatus
    school
    note
    githubUrl
    acceptedConsents {
      id
      wording
      header
    }
    foundOutFrom
    relatedApplications {
      id
      confirmedAt
      position
      currentStatus
    }
    position
    createdAt
    isConfirmed
    withNewApplication
    createdFromIp
    confirmedFromIp
    confirmedAt
    applicationFileId
    signedLink
    availabilityDate
    recruitmentProcessHistory {
      createdAt
      recruiterNote
      recruitmentStatus
      author
    }
  }
`;

import React from "react";
import { TableCell, TableSortLabel } from "@mui/material";
import { PaginationProps } from "../utils/pagination";

export interface HeadCellProps {
  id: string;
  label: string;
  isSortable: boolean;
  align?: "inherit" | "left" | "center" | "right" | "justify";
}
interface TableLabelsProps
  extends Pick<PaginationProps, "orderBy" | "order" | "onSortColumn"> {
  headCells: HeadCellProps[];
}
export const TableLabels: React.FC<TableLabelsProps> = ({
  headCells,
  orderBy,
  onSortColumn,
  order,
}) => (
  <>
    {headCells.map(({ label, id, isSortable, align }) => {
      const handleCellClick = () => (isSortable ? onSortColumn(id) : null);
      if (!isSortable) {
        return (
          <TableCell key={id} align={align || "left"}>
            {label}
          </TableCell>
        );
      }
      return (
        <TableCell key={id} align={align || "left"}>
          <TableSortLabel
            active={orderBy === id}
            direction={orderBy === id ? order : "asc"}
            onClick={handleCellClick}>
            {label}
          </TableSortLabel>
        </TableCell>
      );
    })}
  </>
);

import { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import _ from "lodash";
import { gql } from "apollo-boost";
import axios from "axios";
import { CreateCandidatePayload } from "../../types";

const CREATE_APPLICATION = gql`
  mutation CreateCandidate($input: CreateCandidateInput!) {
    createCandidate(input: $input) {
      url
      fields
    }
  }
`;

export const useCreateApplication = () => {
  const [formError, setFormError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [createApplication] = useMutation(CREATE_APPLICATION, {
    onError: ({ message, graphQLErrors, networkError, extraInfo }) => {
      console.log(message);
      console.log(graphQLErrors);
      console.log(networkError);
      console.log(extraInfo);

      let errorMsg = _.get(graphQLErrors, "[0].message", null);

      if (typeof errorMsg === "object") {
        errorMsg = _.get(graphQLErrors, "[0].message.message", null);
      }
      setFormError(errorMsg || "Something went wrong. Please try again later.");
    },
  });

  const handleCreateApplication = async ({
    formData,
    uploadedFile,
  }: {
    formData: CreateCandidatePayload;
    uploadedFile: File | undefined;
  }) => {
    setLoading(true);

    var extensionRegex = /(?:\.([^.]+))?$/;

    const extensionMatch = uploadedFile
      ? extensionRegex.exec(uploadedFile.name)
      : null;

    const attachmentFileExtension = extensionMatch && extensionMatch[1];
    const response = await createApplication({
      variables: {
        input: { ...formData, attachmentFileExtension },
      },
    });

    if (uploadedFile) {
      const url: string = _.get(response, "data.createCandidate.url");
      const fields = _.get(response, "data.createCandidate.fields", null);
      const fileFormData = new FormData();

      if (fields) {
        fileFormData.append("Content-Type", uploadedFile.type);
        Object.entries(fields).forEach(([k, v]) => {
          if (typeof v === "string") fileFormData.append(k, v);
        });
        fileFormData.append("file", uploadedFile);
      }

      axios.post(url, fileFormData).then(
        () => {
          setLoading(false);
          setIsSuccess(true);
        },
        () => {
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
      setIsSuccess(true);
    }
  };
  return {
    handleCreateApplication,
    formError,
    isSuccess,
    isLoading,
  };
};

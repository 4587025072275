import React, { useState } from "react";
import { Routes } from "../../routes";
import { StyledContainer } from "../../components/StyledContainer";
import Candidate from "../../components/Candidate";
import { useHistory } from "react-router-dom";
import { HeadCellProps } from "../../components/TableLabels";
import TableView from "../../components/TableView";
import CustomizedProgressBar from "../../components/Loader";
import CandidatesFilter from "../../components/CandidatesFilter";
import useCandidatesQuery from "./useCandidatesQuery";
import usePagination from "../../utils/pagination";

const initialOrder = "confirmedAt";
const initialSortingOrder = "desc";
export const Candidates: React.FC = (props) => {
  const { ...restProps } = usePagination({
    orderBy: initialOrder,
    order: initialSortingOrder,
  });

  const { push, location } = useHistory();

  const { loading, candidates, newCursor, fetchMore, error } =
    useCandidatesQuery({
      rows: restProps.rows,
      order: restProps.order,
      orderBy: restProps.orderBy,
      filter: restProps.filter,
    });

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 20,
          color: "rgb(255, 36, 24)",
          marginTop: 50,
        }}>
        {error.graphQLErrors[0].message.message || "Unexpected Error"}
      </div>
    );
  }

  const fetchMoreHandler = async () => {
    if (!fetchMore || !newCursor) {
      return;
    }
    setIsLoadingMore(true);
    await fetchMore({
      variables: {
        pagination: {
          cursor: newCursor,
          limit: restProps.rows,
        },
        order: {
          order: restProps.order,
          orderBy: restProps.orderBy,
        },
        filter: {
          nameSurname: restProps.filter.nameSurname,
          recruitmentStatus: restProps.filter.status,
          confirmedAt: restProps.filter.confirmedAt,
        },
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult || !prevResult.candidateList) {
          return prevResult;
        }
        const prevData = prevResult.candidateList.candidates;
        const moreData = fetchMoreResult.candidateList.candidates;
        fetchMoreResult.candidateList = {
          cursor: fetchMoreResult.candidateList.cursor,
          candidates: [...prevData, ...moreData],
        };
        return fetchMoreResult;
      },
    });
    setIsLoadingMore(false);
  };

  const headCells: HeadCellProps[] = [
    { id: "name", label: "Name", isSortable: true, align: "inherit" },
    { id: "surname", label: "Surname", isSortable: true },
    { id: "cv", label: "CV", isSortable: false },
    { id: "position", label: "Position", isSortable: true },
    { id: "status", label: "Status", isSortable: true },
    { id: "confirmedAt", label: "Date", isSortable: true },
  ];

  const handleClick = (id: string) => {
    push(`/${Routes.Candidate}/` + id, { search: location.search });
  };

  return (
    <StyledContainer>
      <CandidatesFilter
        onChangeFilter={restProps.onChangeFilter}
        filter={restProps.filter}
      />
      {loading || !candidates ? (
        <CustomizedProgressBar />
      ) : (
        <TableView
          headCells={headCells}
          candidates={candidates}
          fetchMoreHandler={fetchMoreHandler}
          isLoadingMore={isLoadingMore}
          newCursor={newCursor}
          tableBody={
            candidates &&
            candidates.map((candidate) => (
              <Candidate
                key={candidate.id}
                id={candidate.id}
                name={candidate.name}
                surname={candidate.surname}
                signedLink={candidate.signedLink}
                position={candidate.position}
                currentStatus={candidate.currentStatus}
                confirmedAt={candidate.confirmedAt}
                handleClick={handleClick}
              />
            ))
          }
          {...props}
          {...restProps}></TableView>
      )}
    </StyledContainer>
  );
};

export default Candidates;

import React from "react";
import { TableCell, TableRow, IconButton } from "@mui/material";
import {
  Description,
  MailOutline,
  Warning,
  TrendingDown,
  TrendingUp,
  DeleteOutline,
  ArrowForward,
  Filter1,
  Filter2,
  Filter3,
  WorkOutline,
  OpenInNew,
  AssignmentTurnedIn,
} from "@mui/icons-material";

import moment from "moment";
import { Candidate as CandidateType } from "../types";
import { RecruitmentStatus } from "../types.d";

export const Candidate: React.FC<
  Pick<
    CandidateType,
    | "id"
    | "name"
    | "surname"
    | "position"
    | "currentStatus"
    | "signedLink"
    | "confirmedAt"
  > & {
    handleClick: (id: string) => void;
  }
> = ({
  id,
  name = "",
  surname,
  currentStatus,
  position,
  signedLink,
  confirmedAt,
  handleClick,
}) => {
  return (
    <TableRow key={name}>
      <TableCell component="th" scope="row">
        {name?.trim() || "-"}
      </TableCell>
      <TableCell align="left">{surname?.trim() || "-"}</TableCell>
      <TableCell align="left">
        {signedLink ? (
          <a
            style={{
              color: "inherit",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              width: 40,
              height: 40,
              flex: "0 0 40px",
              backgroundColor: "rgba(0, 0, 0, .3)",
            }}
            href={signedLink}
            rel="noreferrer"
            target="_blank">
            {name.substring(0, name.indexOf(""))}
            <Description style={{ color: "#fff" }} />
          </a>
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell align="left">{position?.trim() || "-"}</TableCell>
      <TableCell align="left">
        {currentStatus && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: 10 }}>
              {currentStatus.charAt(0) +
                currentStatus.toLowerCase().replace(/_/g, " ").slice(1)}
            </span>
            {currentStatus ===
            RecruitmentStatus.REJECTED_WITH_FUTURE_POTENTIAL ? (
              <TrendingUp style={{ color: "rgb(255, 36, 24)" }} />
            ) : currentStatus ===
              RecruitmentStatus.REJECTED_WITHOUT_FUTURE_POTENTIAL ? (
              <TrendingDown style={{ color: "rgb(255, 36, 24)" }} />
            ) : currentStatus === RecruitmentStatus.NEW ? (
              <Warning style={{ color: "orange" }} />
            ) : currentStatus ===
              RecruitmentStatus.ACCEPTED_FOR_THE_FIRST_STAGE ? (
              <Filter1 style={{ color: "#15b229" }} />
            ) : currentStatus ===
              RecruitmentStatus.ACCEPTED_FOR_THE_SECOND_STAGE ? (
              <Filter2 style={{ color: "#15b229" }} />
            ) : currentStatus ===
              RecruitmentStatus.ACCEPTED_FOR_THE_THIRD_STAGE ? (
              <Filter3 style={{ color: "#15b229" }} />
            ) : currentStatus === RecruitmentStatus.ACCEPTED_FOR_WORK ? (
              <WorkOutline style={{ color: "#15b229" }} />
            ) : currentStatus ===
              RecruitmentStatus.INVITED_FOR_THE_FIRST_STAGE ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Filter1 style={{ color: "#5452ff" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}>
                  <MailOutline style={{ color: "#5452ff" }} />
                </span>
              </div>
            ) : currentStatus ===
              RecruitmentStatus.RESIGNED_FOR_ANOTHER_JOB_OFFER ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TrendingUp style={{ color: "rgb(255, 36, 24)" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}>
                  <WorkOutline style={{ color: "rgb(255, 36, 24)" }} />
                </span>
              </div>
            ) : currentStatus ===
              RecruitmentStatus.INFORMED_ABOUT_REJECTION_WITHOUT_FUTURE_POTENTIAL ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TrendingDown style={{ color: "rgb(255, 36, 24)" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}>
                  <MailOutline style={{ color: "rgb(255, 36, 24)" }} />
                </span>
              </div>
            ) : currentStatus ===
              RecruitmentStatus.INFORMED_ABOUT_REJECTION_WITH_FUTURE_POTENTIAL ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TrendingUp style={{ color: "rgb(255, 36, 24)" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}>
                  <MailOutline style={{ color: "rgb(255, 36, 24)" }} />
                </span>
              </div>
            ) : currentStatus === RecruitmentStatus.AFTER_THE_FIRST_STAGE ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Filter1 style={{ color: "#15b229" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}>
                  <AssignmentTurnedIn style={{ color: "#15b229" }} />
                </span>
              </div>
            ) : currentStatus ===
              RecruitmentStatus.INVITED_FOR_THE_SECOND_STAGE ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Filter2 style={{ color: "#5452ff" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}>
                  <MailOutline style={{ color: "#5452ff" }} />
                </span>
              </div>
            ) : currentStatus === RecruitmentStatus.AFTER_THE_SECOND_STAGE ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Filter2 style={{ color: "#15b229" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}>
                  <AssignmentTurnedIn style={{ color: "#15b229" }} />
                </span>
              </div>
            ) : currentStatus ===
              RecruitmentStatus.INVITED_FOR_THE_THIRD_STAGE ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Filter3 style={{ color: "#5452ff" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}>
                  <MailOutline style={{ color: "#5452ff" }} />
                </span>
              </div>
            ) : currentStatus === RecruitmentStatus.AFTER_THE_THIRD_STAGE ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Filter3 style={{ color: "#15b229" }} />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 5,
                  }}>
                  <AssignmentTurnedIn style={{ color: "#15b229" }} />
                </span>
              </div>
            ) : currentStatus === RecruitmentStatus.CLOSED_AS_DUPLICATE ? (
              <DeleteOutline />
            ) : (
              <DeleteOutline />
            )}
          </div>
        )}
      </TableCell>
      <TableCell align="left">
        {confirmedAt ? moment(new Date(confirmedAt)).format("DD-MM-YYYY") : "-"}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={() => handleClick(id)}
          style={{ marginRight: 10 }}>
          <ArrowForward />
        </IconButton>
        <a
          href={"candidate/" + id}
          style={{ color: "rgba(0, 0, 0, 0.54)" }}
          rel="noopener noreferrer"
          target="_blank">
          <OpenInNew />
        </a>
      </TableCell>
    </TableRow>
  );
};

export default Candidate;

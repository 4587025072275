import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { PaginationProps } from "../../utils/pagination";
import { Candidate } from "../../types";

const GET_CANDIDATES = gql`
  query (
    $filter: CandidatesFilterInput
    $order: CandidatesOrderInput
    $pagination: PaginationInput
  ) {
    candidateList(filter: $filter, order: $order, pagination: $pagination) {
      cursor
      candidates {
        id
        email
        name
        surname
        phoneNumber
        educationStatus
        school
        note
        githubUrl
        foundOutFrom
        position
        createdAt
        isConfirmed
        withNewApplication
        createdFromIp
        confirmedFromIp
        confirmedAt
        applicationFileId
        signedLink
        currentStatus
      }
    }
  }
`;

interface ICandidatesData {
  candidateList: {
    cursor?: string;
    candidates: Candidate[];
  };
  loading?: boolean;
}

type UseCandidatesProps = Pick<
  PaginationProps,
  "rows" | "order" | "orderBy" | "filter"
>;

function useCandidates(props: UseCandidatesProps) {
  const { data, loading, error, fetchMore } = useQuery<ICandidatesData, {}>(
    GET_CANDIDATES,
    {
      variables: {
        order: {
          orderBy: props.orderBy,
          order: props.order,
        },
        pagination: {
          limit: props.rows,
        },
        filter: {
          nameSurname: props.filter.nameSurname,
          recruitmentStatus: props.filter.status,
          confirmedAt: props.filter.confirmedAt,
        },
      },
    }
  );

  const candidates = data?.candidateList.candidates ?? [];
  const newCursor = data?.candidateList.cursor;
  return { loading, candidates, newCursor, error, fetchMore };
}

export default useCandidates;

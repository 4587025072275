import React from "react";
import {
  ConfirmSignIn,
  ConfirmSignUp,
  SignUp,
  withAuthenticator,
  RequireNewPassword,
  ForgotPassword
} from "aws-amplify-react";
import { Route, Router } from "react-router-dom";
import { Routes } from "./routes";
import CustomLogIn from "./Login";
import Header from "./Header";
import { createBrowserHistory } from "history";
import Amplify from "aws-amplify";
import Candidates from "./pages/Candidates";
import Candidate from "./pages/Candidate";
import NewCandidate from "./pages/NewCandidate";
import "./App.css";

export const history = createBrowserHistory();

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_WEB_CLIENT_ID,
  },
});

const App = () => {
  return (
    <Router history={history}>
      <div className="App">
        <Header />
        <main style={{ paddingTop: 80 }}>
          <Route exact path={`/`} component={Candidates} />
          <Route path={`/${Routes.Candidate}/:id`} component={Candidate} />
          <Route path={`/${Routes.NewCandidate}`} component={NewCandidate} />
        </main>
      </div>
    </Router>
  );
};

export default withAuthenticator(App, false, [
  <CustomLogIn />,
  <SignUp />,
  <ConfirmSignIn />,
  <ConfirmSignUp />,
  <RequireNewPassword />,
  <ForgotPassword />
]);

import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import _ from "lodash";
import { useState } from "react";
import { CANDIDATE_DETAILS_FRAGMENT } from "./candidateFragment";

const EDIT_CANDIDATE = gql`
  ${CANDIDATE_DETAILS_FRAGMENT}
  mutation ($id: ID!, $data: EditCandidateInput!) {
    editCandidateStatus(id: $id, data: $data) {
      ...candidateDetails
    }
  }
`;

function useEditCandidateMutation() {
  const [formError,   setFormError] = useState("");
  const [editCandidateStatus, { loading }] = useMutation(EDIT_CANDIDATE, {
    onError: ({ graphQLErrors }) => {
      let errorMsg = _.get(graphQLErrors, "[0].message", null);

      if (typeof errorMsg === "object") {
        errorMsg = _.get(graphQLErrors, "[0].message.message", null);
      }
      setFormError(errorMsg);
    },
  });
  return { editCandidateStatus, formError, loading };
}

export default useEditCandidateMutation;

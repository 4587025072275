import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Toolbar, IconButton, AppBar, Theme } from "@mui/material";
import { NavLink } from "react-router-dom";
import logo from "./assets/logo.png";
import LogoutButton from "./components/logout-button";
import { PersonAdd } from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
  })
);

export default function Header() {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar} color="primary">
      <Toolbar>
        <NavLink to="/">
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img src={logo} alt="logo" />
          </IconButton>
        </NavLink>
        <div style={{ marginLeft: "auto", display: "flex" }}>
          <NavLink to="/new-candidate" style={{ marginRight: 15 }}>
            <IconButton>
              <PersonAdd style={{ color: "#fff" }} />
            </IconButton>
          </NavLink>
          <LogoutButton />
        </div>
      </Toolbar>
    </AppBar>
  );
}

import {CircularProgress} from "@mui/material";
import { makeStyles } from '@mui/styles';
import React from "react";

const useStyles = makeStyles({
  root: {
    position: "relative",
  },
  fabProgress: {
    color: "black",
    position: "absolute",
    top: "calc(50% - 14px)",
    left: "calc(50% - 14px)",
    zIndex: 1,
  },
});

const LoadingButtonWrapper: React.FC<{ loading: boolean }> = ({ children, loading }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {children}
      {loading && <CircularProgress size={28} className={classes.fabProgress} />}
    </div>
  );
};

export default LoadingButtonWrapper;

import { useHistory } from "react-router";
import { Routes } from "../../../routes";
import { SelectChangeEvent } from "@mui/material";

export function useHandleClick() {
  const {
    push,
    location: { state }
  } = useHistory();

  const handleClick = (
    event: SelectChangeEvent<string>
  ) => {
    push(`/${Routes.Candidate}/` + event.target.value, state);
  };

  return [handleClick] as const;
}
